import instance from './request'

export function login(dataObj) {
  return instance({
    url: 'login',
    method: 'POST',
    params: dataObj
  })
}

//获取工位ID
export function getStationId(dataObj) {
  return instance({
    url: 'equipment/getStationId',
    method: 'POST',
    params: dataObj
  })
}