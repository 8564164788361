<template>
	<div class="login-container">
		<div class="title">Visual可视化管理系统</div>
		<div class="login-box">
			<div class="login-avatar">
				<img src="~assets/img/logo.png" alt="" />
			</div>
			<el-tabs class="tabs" v-model="activeName" @tab-click="handleClick" stretch>
				<el-tab-pane class="tab-item" label="卡号登录" name="first">
					<el-form :model="loginForm1" :rules="rules1" ref="loginFormRef1" class="login-form" @submit.native.prevent>
						<el-form-item prop="cardNo">
							<el-input placeholder="卡号" ref="inputs" prefix-icon="el-icon-bank-card" v-model="loginForm1.cardNo" autofocus @keyup.enter.native="loginByCardNo" clearable></el-input>
						</el-form-item>
						<el-form-item class="form-btn">
							<el-button type="primary" @click="submit('loginFormRef1')">登&emsp;录</el-button>
							<el-button type="info" @click="resetForm('loginFormRef1')">重&emsp;置</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane class="tab-item" label="账号登录" name="second">
					<el-form :model="loginForm2" :rules="rules2" ref="loginFormRef2" class="login-form">
						<el-form-item prop="uname">
							<el-input placeholder="用户名" prefix-icon="el-icon-user-solid" v-model="loginForm2.uname" clearable></el-input>
						</el-form-item>
						<el-form-item prop="pwd">
							<el-input placeholder="密码" show-password prefix-icon="el-icon-lock" v-model="loginForm2.pwd" clearable></el-input>
						</el-form-item>
						<el-form-item class="form-btn">
							<el-button type="primary" @click="submit('loginFormRef2')">登&emsp;录</el-button>
							<el-button type="info" @click="resetForm('loginFormRef2')">重&emsp;置</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>
		
		</div>
	</div>
</template>
<script>
import { login, getStationId } from 'network/login'
export default {
	name: 'loginBox',
	data() {
		return {
			//选项卡默认显示的模块
			activeName: "first",
			//选项卡的下标
			activeIndex: 0,
			equipmentNo: "",
			eqId: "",
			stationId: "",
			cardNo: "",
			isNotTouch: 0,
			timer: "",
			//卡号登录表单
			loginForm1: {
				cardNo: ''
			},
			rules1: {
				cardNo: [{
						required: true,
						message: '请输入卡号',
						trigger: 'blur'
					},
					{
						min: 6,
						message: '卡号必须为10位数字',
						trigger: 'blur'
					}
				]
			},
			//账号登录表单
			loginForm2: {
				uname: '',
				pwd: ''
			},
			//账号登录表单校验规则
			rules2: {
				uname: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					},
					{
						min: 3,
						max: 10,
						message: '长度在 3 到 8 个字符',
						trigger: 'blur'
					}
				],
				pwd: [{
					required: true,
					message: '请输入密码',
					trigger: 'blur'
				}]
			},
			//请求参数对象
			dataObj: {}
		}
	},
	beforeMount() {
		console.log("initPage");
	},
	mounted() {
		this.getStationId1();
	},
	created() {
		// this.timer = setInterval(this.changeFocus, 3500);
		this.changeFocus();
		this.equipmentNo = this.utils.getUrlKey("SN");
		this.loginForm1.cardNo = "2711136719";
		this.isNotTouch = this.utils.getUrlKey("NotTouch");
		sessionStorage.setItem("isNotTouch", this.isNotTouch);
		console.log("是否为触摸屏"+this.isNotTouch);
		sessionStorage.setItem("cardNo", this.loginForm1.cardNo);
		console.log("设备号"+this.utils.getUrlKey("SN"));
		
	},
	beforeDestroy() {
		if(this.timer) {
			clearInterval(this.timer); //关闭
		}  //利用vue的生命周期函数
	},
	methods: {
		//输入卡号时自动聚焦
		changeFocus(){
			this.$nextTick(function () {
				this.$refs.inputs.$el.querySelector('input').focus();
			});
		},
		//选项卡切换事件
		handleClick(tab) {
			this.activeIndex = tab.index;
		},
		getStationId1() {
			// console.log(123)
			sessionStorage.setItem("equipmentNo", this.equipmentNo)
			let obj = {
				equipmentNo: this.equipmentNo,
			}
			// console.log(this.equipmentNo)
			getStationId(obj).then(res => {
				this.eqId = res.data.data.eqId;
				this.stationId = res.data.data.stationId;
				sessionStorage.setItem("eqId", this.eqId);
				sessionStorage.setItem("stationId", this.stationId);
				console.log(sessionStorage.getItem("stationId"));
				this.loginByCardNo();
			})
		},
		//卡号登录
		loginByCardNo() {
			this.dataObj = {
				cardno: this.loginForm1.cardNo,
				eqid: this.eqId
			}
			login(this.dataObj).then(res => {
				if(res.data.code == 0 ) {
					this.$message.success('登录成功')
					var that = this;
					setTimeout(function() {
						that.$router.push("/home");
					},1000)
					window.sessionStorage.setItem("userName",res.data.data.userName)
					window.sessionStorage.setItem('userId',res.data.data.userId);
					window.sessionStorage.setItem('cardNo',res.data.data.cardNo);	
				}else {
					this.loginForm1.cardNo = "";
					this.changeFocus();
					this.$message.error({
						showClose: true,
						message: res.data.msg,
						center: true,
						duration: 1000
					})
				}
			})
		},
		//账号登录
		loginByUsername() {
			this.dataObj = {
				username: this.loginForm2.uname,
				password: this.loginForm2.pwd
			}
			login(this.dataObj).then(res => {
				if(res.data.code == 0 ) {
					this.$message.success('登录成功')
					var that = this;
					setTimeout(function() {
						that.$router.push("/home");
					},1000)
					window.sessionStorage.setItem("userName",res.data.data.userName)
					window.sessionStorage.setItem('userId',res.data.data.userId);
					window.sessionStorage.setItem('cardNo',res.data.data.cardNo);	
				}else {
					this.$message.error({
						showClose: true,
						message: res.data.msg,
						center: true,
						duration: 10000
					})
				}
			})
		},
		//表单提交事件
		submit(form) {
			this.$refs[form].validate((valid) => {
				if (!valid) return false
				if (this.activeIndex == 0) {
					this.loginByCardNo()
				} else {
					this.loginByUsername()
				}
			})
		},
		//表单重置事件
		resetForm(form) {
			this.$refs[form].resetFields()
		}
	}
}
</script>
<style lang="scss">
	.login-container {
		.el-tabs__item.is-active {
			color: rgba(58,132,171,1);
		}
		.el-tabs__item:hover {
			color: rgba(58,132,171,1);
		}
		.el-tabs__active-bar {
			background: rgba(58,132,171,1);
		}
		.el-tabs__item {
		  font-size: 1.375rem !important;
		  height: 3.125rem;
		}
		.el-input {
			font-size: 1.25rem;
		}
		.el-input__inner {
			height: 3rem !important;
		}
		.el-input__icon {
			width: 1rem !important;
			line-height: 3rem !important;
			margin-right: 0.625rem;
		}
		.el-message-box__content {
			font-size: 1.125rem !important;
		}
	}

</style>

<style lang="scss" scoped>
$login-box-bgc: #f6f6f6;
.login-container {
	width: 100%;
	height: 100%;
	background-image: linear-gradient(180deg, #3a7aa7, #39bec0);
	overflow: hidden;
	.title {
		position: absolute;
		left: 50%;
		top: 20%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%); 
		-moz-transform: translate(-50%, -50%); 
		-webkit-transform:translate(-50%, -50%); 
		-o-transform: translate(-50%, -50%); 
		font-size: 3.125rem;
		color: $login-box-bgc;
		letter-spacing: 6px;
		z-index: 10;
	}
	.login-box {
		width: 28.125rem;
		height: 25rem;
		background-color: $login-box-bgc;
		position: absolute;
		left: 50%;
		top: 60%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%); 
		-moz-transform: translate(-50%, -50%); 
		-webkit-transform:translate(-50%, -50%); 
		-o-transform: translate(-50%, -50%); 
		// border-radius: 500px;
		$avatarSize: 10rem;
		.login-avatar {
			width: $avatarSize;
			height: $avatarSize;
			padding: 0.625rem;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 50%;
			transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%); 
			-moz-transform: translate(-50%, -50%); 
			-webkit-transform:translate(-50%, -50%); 
			-o-transform: translate(-50%, -50%); 
			border: 2px solid #e6e6e6;
			background-color: $login-box-bgc;
			border-radius: 50%;
			overflow: hidden;
			box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.4);
	
			img {
				width: 100%;
				border-radius: 50%;
			}
		}
		
		.tabs {
			margin-top: 6.25rem;
		}
	
		.login-form {
			padding: 0 1.5rem;
			padding-top: 2.5rem;
			position: relative;
	
			.form-btn {
				// float: right;
				display: flex;
				justify-content: space-around;
				// height: 1.5rem;
			}
		}
	}
}
</style>
